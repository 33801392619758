import * as React from "react"
import PropTypes from "prop-types"

import HeaderFull from "./header/header-full"
import FooterNewUpdate from "./footer-new-update"
import SectionCookieNotice from './sections/section-cookie-notice';
import SectionCookieNoticeMobile from './sections/section-cookie-notice-mobile';

const LayoutNewDemo = ({ children, isContactPage }) => (
  <>
    <HeaderFull 
      isContactPage={isContactPage ? true : false}
    />
    <div>
      <main>{children}</main>
    </div>
    <chatlio-widget widgetid="d932bc71-e6bc-4d0a-7bfc-22ebd2283d28"></chatlio-widget>
    <FooterNewUpdate />   
    <SectionCookieNotice />
    <SectionCookieNoticeMobile /> 
  </>
)

LayoutNewDemo.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutNewDemo
 